//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-940:_3892,_568,_4444,_7304,_2268,_9440,_304,_5964;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-940')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-940', "_3892,_568,_4444,_7304,_2268,_9440,_304,_5964");
        }
      }catch (ex) {
        console.error(ex);
      }