import shopifyGenericDefaults from '../shopify-generic/config.js';

const isProductPage = window.location.pathname.startsWith('/products/');

const baseFitmentFields = ['RideType', 'Year', 'Make', 'Model'];
const extraFitmentFields = isProductPage ? ['Submodel'] : [];

globalThis.Convermax.quickView = (item) => (e) => handleQuickView(item, e);

globalThis.Convermax.closeQuickView = () => () => {
  const quickViewModal = document.querySelector('#QuickShopModal');
  quickViewModal.classList.remove('modal--is-active');
  document.documentElement.classList.remove('modal-open');
};

globalThis.Convermax.addToCart = (id) => (e) => handleAddToCart(id, e);

function responseHandler({ Facets, ...rest }) {
  return { Facets: isProductPage ? Facets : Facets.filter((f) => f.FieldName !== 'Submodel'), ...rest };
}

export default {
  includes: ['shopify-generic'],
  responseHandler,
  ...shopifyGenericDefaults,
  collapsibleAnimationTime: 100,
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['HeaderVehicleWidget', 'HeaderVehicleWidgetStub'].includes(w.name),
    ),
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 900,
      initCollapsed: true,
    },
    {
      name: 'HeaderVehicleWidgetStub',
      type: 'VehicleWidget',
      location: '#cm-ymm-stub',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 900,
      initCollapsed: true,
    },
  ],
};

function handleQuickView(item, e) {
  const modal = window.document.querySelector('#QuickShopModal');
  modal.classList.add('modal--is-active');
  document.documentElement.classList.add('modal-open');

  // hide modal by clicking outside
  window.document.addEventListener('click', (e) => {
    const isClosest = e.target.closest('.modal__inner');
    const isOpenButton = e.target.closest('.quick-product__btn');

    if (!isClosest && !isOpenButton && modal.classList.contains('modal--is-active')) {
      modal.classList.remove('modal--is-active');
      document.documentElement.classList.remove('modal-open');
    }
  });

  // title
  window.document
    .querySelectorAll('#QuickShopModal .h2.product-single__title')
    .forEach((elem) => (elem.innerHTML = item.title));

  // image
  const productImage = window.document.querySelector('#QuickShopModal .photoswipe__image');
  productImage.src = item.imageOrDefault(item.resizeImage(item.image));
  productImage.alt = item.removeHTML(item.title);
  productImage.setAttribute('onError', item.onImageError(e));

  // description
  window.document.querySelectorAll('#QuickShopModal .product-block--description').forEach((elem) => {
    elem.innerHTML = item.body_html;
  });

  // price
  const priceLabel = window.document.querySelector('#QuickShopModal .product__price-label');
  priceLabel.innerHTML = `
    ${item.formatPrice(item.price).split('.')[0]}<sup>${item.formatPrice(item.price).split('.')[1]}</sup>
  `;

  const comparePriceWrapper = window.document.querySelector('#QuickShopModal .product__price--compare');
  comparePriceWrapper.innerHTML = '';

  if (item.on_sale) {
    comparePriceWrapper.innerHTML = `
      <span aria-hidden="true">
        ${item.formatPrice(item.compare_at_price).split('.')[0]}
        <sup>${item.formatPrice(item.compare_at_price).split('.')[1]}</sup>
      </span>
    `;

    priceLabel.innerHTML += `
      <span class="product__price-savings">Save ${item.formatPrice(item.compare_at_price - item.price)}</span>
    `;
  }

  // add-to-cart button
  const paymentButtons = window.document.querySelector('#QuickShopModal .payment-buttons');

  if (item.variant_ids.length <= 1 && !item.out_of_stock) {
    paymentButtons.innerHTML = `
      <button type="submit" class="btn btn--full add-to-cart btn--secondary">
        <span data-add-to-cart-text="" data-default-text="Add to cart">
          Add to cart
        </span>
      </button>
      <select name="id" data-product-select="" class="product-single__variants"><option selected="selected" value="${item.variant_ids
        .slice(0, 1)
        .shift()}">
        </option>
      </select>
    `;
  } else if (item.variant_ids.length > 1 && !item.out_of_stock) {
    paymentButtons.innerHTML = `
      <a href="${item.url}" class="btn btn--full add-to-cart btn--secondary">
        <span>
          Choose options
        </span>
      </a>
    `;
  } else {
    paymentButtons.innerHTML = `
      <button disabled class="btn btn--full add-to-cart btn--secondary">
        <span>
          Sold Out
        </span>
      </button>
    `;
  }
}

function handleAddToCart(id, e) {
  const btn = e.currentTarget;
  const visibleBtn = btn.querySelector('.btn');
  visibleBtn.classList.add('btn--loading');

  fetch(window.theme.routes.cartAdd, {
    method: 'POST',
    body: JSON.stringify({
      items: [
        {
          id,
          quantity: 1,
        },
      ],
    }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then(
      function (data) {
        if (data.status === 422 || data.status === 'bad_request') {
          if (data.description) {
            alert(data.description);
          }
        } else {
          const product = data;
          document.dispatchEvent(
            new CustomEvent('ajaxProduct:added', {
              detail: {
                product,
              },
            }),
          );
        }

        visibleBtn.classList.remove('btn--loading');
      }.bind(this),
    );
}
