
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function scopeColorArrColorUrl1(colorOption, colorOptionIndex) {
                    var colorArr = colorOption.split('|');
                    var color = colorArr[0];
                    var url = colorArr[2];
                    return [_createElement('a', {
                            'className': 'color-swatch color-swatch--small color-swatch--' + color + ' color-swatch--with-image',
                            'style': { backgroundColor: color },
                            'href': url,
                            'key': '23341'
                        }, _createElement('span', { 'className': 'visually-hidden' }, color))];
                }
                function repeatColorOption2(colorOption, colorOptionIndex, colorArr, color, url) {
                    return scopeColorArrColorUrl1.apply(this, [
                        colorOption,
                        colorOptionIndex
                    ]);
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', {
                    'className': 'grid-item grid-product',
                    'data-product-handle': this.handle,
                    'data-product-id': this.id
                }, _createElement('div', { 'className': 'grid-item__content' }, _createElement('div', { 'className': 'grid-product__actions' }, _createElement('button', {
                    'className': 'btn btn--circle btn--icon quick-product__btn js-modal-open-quick-modal-' + this.id + ' small--hide',
                    'title': 'Quick shop',
                    'tabIndex': '-1',
                    'data-handle': this.handle,
                    'onClick': window.Convermax.quickView(this),
                    'type': 'button'
                }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon icon-search',
                    'viewBox': '0 0 64 64'
                }, _createElement('path', {
                    'className': 'cls-1',
                    'd': 'M47.16 28.58A18.58 18.58 0 1 1 28.58 10a18.58 18.58 0 0 1 18.58 18.58zM54 54L41.94 42'
                })), _createElement('span', { 'className': 'icon__fallback-text' }, 'Quick shop')), !this.out_of_stock ? _createElement('button', {
                    'type': 'submit',
                    'className': 'text-link quick-add-btn js-quick-add-btn add-to-cart',
                    'title': 'Add to cart',
                    'tabIndex': '-1',
                    'onClick': window.Convermax.addToCart(this.variant_ids[0]),
                    'key': '747'
                }, _createElement('span', { 'className': 'btn btn--circle btn--icon' }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon icon-plus',
                    'viewBox': '0 0 64 64'
                }, _createElement('path', {
                    'className': 'cls-1',
                    'd': 'M32 9v46m23-23H9'
                })), _createElement('span', { 'className': 'icon__fallback-text' }, 'Add to cart'))) : null, _createElement('select', {
                    'name': 'id',
                    'data-product-select': true,
                    'className': 'product-single__variants'
                }, _createElement('option', {
                    'selected': 'selected',
                    'value': this.variant_ids[0]
                }))), _createElement('div', { 'className': 'grid-item__link' }, _createElement('div', { 'className': 'grid-product__image-wrap' }, _createElement('div', { 'className': 'grid-product__tags' }, this.on_sale && !this.out_of_stock ? _createElement('div', {
                    'className': 'grid-product__tag grid-product__tag--sale',
                    'key': '1590'
                }, '\n            Sale\n          ') : null, this.out_of_stock ? _createElement('div', {
                    'className': 'grid-product__tag grid-product__tag--sold-out',
                    'key': '1741'
                }, 'Sold out') : null), _createElement('a', {
                    'href': this.url,
                    'className': 'grid__image-ratio grid__image-ratio--square'
                }, _createElement('img', {
                    'className': 'grid__image-contain lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'sizes': '100vw'
                }))), _createElement('div', { 'className': 'grid-item__meta' }, this.product_colors && this.product_colors.length > 1 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'grid-product__colors',
                        'key': '2216'
                    },
                    _map(this.product_colors.slice(0, 4), repeatColorOption2.bind(this)),
                    this.product_colors.length > 4 ? [_createElement('small', {
                            'className': 'color-swatch__more',
                            'key': '27671'
                        }, _createElement('a', { 'href': this.url }, '+', this.product_colors.length - 4))] : null
                ]) : null, _createElement('div', { 'className': 'grid-item__meta-main' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'grid-product__title'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'grid-item__meta-secondary' }, _createElement('div', { 'className': 'grid-product__price' }, !this.price_varies ? [
                    _createElement('span', {
                        'className': 'grid-product__price--current',
                        'key': '32851'
                    }, _createElement('span', { 'aria-hidden': 'true' }, '\n                  ', this.formatPrice(this.price).split('.')[0], '\n                  ', _createElement('sup', {}, this.formatPrice(this.price).split('.')[1]))),
                    this.on_sale ? _createElement('rt-vitrual', { 'key': '32853' }, _createElement('span', { 'className': 'grid-product__price--original' }, _createElement('span', { 'aria-hidden': 'true' }, '\n                    ', this.formatPrice(this.compare_at_price).split('.')[0], '\n                    ', _createElement('sup', {}, this.formatPrice(this.compare_at_price).split('.')[1]))), _createElement('span', { 'className': 'grid-product__price--savings' }, 'Save ', this.formatPrice(this.save_price))) : null
                ] : null, this.price_varies ? [_createElement('span', {
                        'className': 'grid-product__price--current',
                        'key': '41871'
                    }, _createElement('span', { 'aria-hidden': 'true' }, '\n                  From ', this.formatPrice(this.price).split('.')[0], '\n                  ', _createElement('sup', {}, this.formatPrice(this.price).split('.')[1]), '\n                  to ', this.formatPrice(this.max_price).split('.')[0], '\n                  ', _createElement('sup', {}, this.formatPrice(this.max_price).split('.')[1])), this.on_sale ? _createElement('span', {
                        'className': 'grid-product__price--savings',
                        'key': '4696'
                    }, '\n                  Save up to ', this.formatPrice(this.save_price), '\n                ') : null)] : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'Also fits your vehicle:')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []