//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-940:_3552,_4496,_9136,_5095,_3644,_2972,_7376,_1188;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-940')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-940', "_3552,_4496,_9136,_5095,_3644,_2972,_7376,_1188");
        }
      }catch (ex) {
        console.error(ex);
      }